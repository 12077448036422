<template>
    <div class="loading-container" v-if="visible">
        <div class="loading-mask">
            <div class="road">
                <img :style="{left:position+'%'}" :src="require('@/static/image/kuge.png')"/>
                <div class="road-back"></div>
                <div class="road-run" :style="{left:(position+30)+'%'}"></div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'loadingV1',
    data () {
      return {
        position: -10,
        visible: false
      }
    },
    created () {
      this.timeRun()
    },
    methods: {
      timeRun () {
        if (this.position > 80) {
          this.position = -20
          this.timeRun()
        } else {
          setTimeout(() => {
            this.position += 1
            this.timeRun()
          }, 20)
        }
      },
      show () {
        this.position = -10
        this.visible = true
      },
      hide () {
        setTimeout(() => {
          this.visible = false
        }, 300)
      }
    }
  }
</script>

<style scoped lang="scss">
    .loading-container {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        .loading-mask {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .1);
            display: flex;
            align-items: center;
            justify-content: center;

            .road {
                width: 10%;
                height: 47px;
                position: relative;

                img {
                    width: 30px;
                    position: absolute;
                }

                .road-back {
                    position: absolute;
                    height: 2px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: #fff;
                }

                .road-run {
                    position: absolute;
                    height: 2px;
                    right: -10%;
                    bottom: 0;
                    background: rgba(0, 0, 0, .2);
                }
            }
        }
    }
</style>